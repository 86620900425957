import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MapComponent } from './_pages/map/map.component';
import { AboutUsComponent } from './_pages/about-us/about-us.component';
import { InitiativeComponent } from './_pages/initiative/initiative.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './_framework/header/header.component';
import { MainComponent } from './_framework/main/main.component';
import { FooterComponent } from './_framework/footer/footer.component';
import { MarkerService } from './_services/map/marker.service';
import { PopUpService } from './_services/map/pop-up.service';
import { HttpClientModule } from '@angular/common/http';
import { SponsorGridComponent } from './_components/partner-grid/sponsor-grid.component';
import { PartnerService } from './_services/partner/partner.service';
import { SubmitFormComponent } from './_components/submit-form/submit-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AcknowledgementsComponent } from './_pages/acknowledgements/acknowledgements.component';
import { BookListComponent } from './_pages/book-list/book-list.component';
import { BookComponent } from './_components/book/book.component';
import { VideoComponent } from './_components/video/video.component';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    AboutUsComponent,
    InitiativeComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    SponsorGridComponent,
    SubmitFormComponent,
    AcknowledgementsComponent,
    BookListComponent,
    BookComponent,
    VideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    MarkerService,
    PopUpService,
    PartnerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
