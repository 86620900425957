import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  openPosition: {};

  private width: number;
  private height: number;

  constructor() { }

  goToLink(url: string){
    window.open(url, '_blank');
  }

  makePopup(video: any): string {
    if (window.innerWidth < 768) {
      this.width = 200;
      this.height = 300;
    }

    if (window.innerWidth >= 768) {
      this.width = 500;
      this.height = 380;
    }

    const titleRef = `<h4>${video.title}</h4>`;

    const videoRef = `<a href="https://www.youtube.com/watch?v=${video.youtubeid}" target="_blank">${video.title}</a>`;

    const videoFrame = `` +
      `<iframe class="video-frame" style="width: ${this.width}px; height: ${this.height}px" ` +
      `src="https://www.youtube-nocookie.com/embed/${video.youtubeid}?autoplay=1&rel=0" frameborder="0" ` +
      `allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    let bookRef = `` +
      `<p>Tratto da ${video.book} ` +
      `di ${video.author}</p>`;

    if ('booklink' in video) {
      bookRef = `` +
        `<p>Tratto da <a href="${video.booklink}" target="_blank"> ${video.book} </a> ` +
        `di ${video.author}</p>`;
    }

    const readerRef = `<p>Letto da ${video.reader}</p>`;

    const videoBtn = `<a class="btn btn-outline-secondary text-secondary" href="https://www.youtube.com/watch?v=${video.youtubeid}" target="_blank">Vai al video!</button>`;

    if (video.embed && window.innerWidth >= 768) {
      return `` +
        `<div >` +
          titleRef +
          videoFrame +
          bookRef +
          readerRef +
        `</div>`;
    } else {
      return `` +
        `<div>` +
          titleRef +
          bookRef +
          readerRef +
          videoBtn +
        `</div>`
        ;
    }
  }


}
