import { Component, OnInit } from '@angular/core';
import { Video } from 'src/app/_services/video';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  private videoDB: Video;

  constructor() { }

  ngOnInit(): void {

  }

}
