<div class="m-4">
  <div class="card-deck">
    <div class="card">
      <img class="card-img-top" src="/assets/img/content-image/chi-siamo-0.jpg">
      <div class="card-body">
        <h2 class="card-title">Chi siamo?</h2>
        <p class="card-text">
          Siamo “Lettura che passione”, un gruppo di lettori appassionati provenienti della provincia di Oristano
          che amano la lettura e i libri.
        </p>
      </div>
    </div>


    <div class="card">
      <img class="card-img-top" src="/assets/img/content-image/cosa-facciamo-4.jpg">
      <div class="card-body">
        <h2 class="card-title">Cosa facciamo</h2>
        <p class="card-text">
          Organizziamo attività di animazione alla lettura per tutte le età e gruppi di confronto su letture e
          autori contemporanei e non. Ci è capitato di partecipare, con molto piacere, a diverse iniziative
          culturali in Sardegna.
        </p>
      </div>
    </div>

    <div class="card ">
      <!--Image via https://www.pickpik.com/ (free to use for personal and commercial projects)-->
      <img class="card-img-top" src="/assets/img/content-image/contatti-5.jpg">
      <div class="card-body">
        <h2 class="card-title">Contatti</h2>
        <p class="card-text">
          “Lettura che passione” è un gruppo aperto a tutti coloro che condividono la passione per un buon libro.
        </p>
        <p class="card-text">
          Per informazioni o dirci cosa ne pensi di questa iniziativa puoi contattarci a:
          info@letturachepassione.it
        </p>
      </div>
    </div>
  </div>
</div>
