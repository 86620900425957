

<div class="m-3 mt-4 card">
  <h1 class="card-header ">Ringraziamenti</h1>
  <p class="card-body">
    Vogliamo ringraziare tutti coloro che generosamente hanno contribuito a realizzare questo progetto: <br><br>
    Carlotta Brungiu per il logo. <br><br>
    Virgilio Alfano, Paolo Campanaro, Ugo Consolo, Bachisio Piga, Salvatora Cottone, Antonio Scanu, Luana Sanna, Simone Marras e Simonetta Garbin per le fotografie. <br><br>
    Giorgia Spanu per i dipinti. <br><br>
    Gli eredi della carissima Andreina Frau. <br><br>
    Don Francesco Murana. <br><br>
    E tutti coloro che ci hanno sostenuto e supportato ma che preferiscono restare anonimi. <br>
  </p>
</div>
