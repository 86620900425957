import { Component, OnInit } from '@angular/core';
import {Partner} from '../../_services/partner/partner';
import {PartnerService} from '../../_services/partner/partner.service';



@Component({
  selector: 'app-sponsor-grid',
  templateUrl: './sponsor-grid.component.html',
  styleUrls: ['./sponsor-grid.component.scss']
})
export class SponsorGridComponent implements OnInit {
  partners: Partner[] = [];

  constructor(private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.getPartners();
  }

  getPartners() {
    this.partnerService.getPartners().subscribe(partners => this.partners = partners);
  }

}
