import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import {PopUpService} from './pop-up.service';
import { DBService } from '../db.service';


@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  embedVideo = true;
  private maxWidth: any;
  private maxHeight: any;

  constructor(private popupService: PopUpService, private dbService: DBService) { }

  makeMarkers(map: L.map): void {
    this.dbService.get_video_db().subscribe((videoDB: any) => {
      const markers = L.markerClusterGroup({
        iconCreateFunction(cluster) {
          const html = '<div class="marker-cluster">' + cluster.getChildCount() + '</div>';
          return L.divIcon({
            html,
            className: 'mycluster',
            iconSize: L.point(48, 48),
            iconAnchor: L.point(24, 48)
          });
        },
        maxClusterRadius: 48
      });

      for (const video of videoDB) {
        const lat = video.coordinates[0];
        const lon = video.coordinates[1];
        const marker = L.marker([lon, lat], {
          icon: new L.divIcon({
            html: '<div class="marker-single"></div>',
            className: 'marker-single',
            iconSize: L.point(48, 48),
            iconAnchor: L.point(24, 48)
          })
        });


        if (window.innerWidth < 768) {
          this.maxWidth = 200;
          this.maxHeight = 300;
        } else if (window.innerWidth >= 768) {
          this.maxWidth = 600;
          this.maxHeight = 540;
        }

        marker.bindPopup(this.popupService.makePopup(video),
        {
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight
        });

        markers.addLayer(marker);
      }

      map.addLayer(markers);
    });
  }
}
