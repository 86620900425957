<link rel="stylesheet" href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
      integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
      crossorigin=""/>

<div class="container">
  <div class="card mt-4">
    <div class="card-body">
      <h3 class="card-title">Benvenuto</h3>
      <p class="card-text">
        Attraverso questa cartina potrai fare un viaggio virtuale nei luoghi e tradizioni della Sardegna attraverso
        gli occhi dei personaggi che vivono nei libri.
        Accompagnato dalle voci dei nostri lettori potrai visitare quei luoghi, meditare e poi ripartire per
        un'altra tappa.
      </p>
      <p class="card-text">
        Per iniziare il tuo viaggio seleziona una destinazione dalla mappa e lasciati trasportare dalla narrazione.
      </p>
    </div>
  </div>


  <div class="card mt-4">
    <div class="map-container">
      <div class="map-frame">
        <div id="map"></div>
      </div>
    </div>
  </div>

</div>
