import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutus = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consequat sem et sapien ornare pellentesque. Aliquam cursus, erat nec tempus euismod, quam est ultricies tellus, ut molestie ante ex nec nibh. Donec posuere lectus porttitor, bibendum tellus vel, dapibus neque. Integer accumsan vitae lorem eget tincidunt. Nulla tempor accumsan luctus. Vestibulum id velit eget turpis tincidunt vulputate bibendum id tortor. Aenean urna orci, faucibus vel ligula non, scelerisque aliquam odio. Pellentesque a est varius sapien iaculis fringilla quis non augue. Curabitur tincidunt sapien vel elit interdum, eget dapibus ligula molestie. Donec vitae nibh lobortis, porta mauris et, mattis magna. Cras semper nisi vitae dolor dignissim, et ullamcorper lorem pellentesque. Nunc vitae nunc sit amet metus eleifend aliquet id a urna. Nulla nec tellus lorem. Donec id tincidunt quam. Fusce id bibendum urna.';

  constructor() { }

  ngOnInit(): void {
  }

}
