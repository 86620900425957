<form [formGroup]="submitForm" (ngSubmit)="onSubmit(submitForm.value)">
  <label for="ftitle">Titolo: </label>
  <input type="text" id="ftitle" formControlName="ftitle"><br>

  <label for="ftext">Citazione: </label>
  <input type="text" id="ftext" formControlName="ftext"><br>

  <label for="fauthor">Autore: </label>
  <input type="text" id="fauthor" formControlName="fauthor"><br>

  <label for="fbook">Libro: </label>
  <input type="text" id="fbook" formControlName="fbook"><br>

  <label for="fpos">Posizione: </label>
  <input type="text" id="fpos" formControlName="fpos"><br>

  <label for="fnote">Note: </label>
  <input type="text" id="fnote" formControlName="fnote"><br>

  <button type="submit">Invia</button>

</form>
