
<nav class="container-lg navbar navbar-expand-lg navbar-dark bg-primary pl-0 pr-0">
  <a routerLink="/" class="navbar-brand p-0 m-0">
    <div class="d-inline-block align-middle">
      <img class="logo-img" src="/assets/img/loghi/logo-viaggiamo-in-sardegna.png">
    </div>
    <div class="d-inline-block align-middle">
      <h1 class="logo-text-pri text-wrap m-0"> VIAGGIAMO <br> IN SARDEGNA </h1>
      <h2 class="logo-text-sec m-0">CON I RACCONTI DEL GRUPPO "LETTURA CHE PASSIONE"</h2>
    </div>

  </a>

  <button class="navbar-toggler justify-content-end pl-0" type="button" (click)="toggleNavbar = !toggleNavbar"
          data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Apri menu">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end" id="navbar" [ngbCollapse]="toggleNavbar">
    <div class="navbar-nav">
      <a (click)="toggleNavbar = !toggleNavbar" class="nav-item navbar-right nav-link active m-2" routerLink="/libri">I Libri</a>
      <a (click)="toggleNavbar = !toggleNavbar" class="nav-item nav-link active m-2" routerLink="/iniziativa">Iniziativa</a>
      <a (click)="toggleNavbar = !toggleNavbar" class="nav-item nav-link active m-2" routerLink="/chi-siamo">Chi Siamo</a>
    </div>
  </div>
</nav>



