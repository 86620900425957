import { Component, OnInit } from '@angular/core';
import { DBService } from 'src/app/_services/db.service';
import { Book } from 'src/app/_services/book';

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss']
})
export class BookListComponent implements OnInit {
  bookDB: Book[] = [];

  constructor(private db: DBService) { }

  ngOnInit(): void {
    this.db.get_book_db().subscribe(bookDB => {
      console.log(bookDB);
      this.bookDB = bookDB;
    });
  }

}
