import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Video } from './video';
import { Observable, of } from 'rxjs';
import { Book } from './book';

@Injectable({
  providedIn: 'root'
})
export class DBService {
  private videoDbUrl = '/assets/data/videoDB.json';

  constructor(private http: HttpClient) { }

  get_video_db(): Observable<Video[]>{
    return this.http.get<Video[]>(this.videoDbUrl);
  }

  get_book_db(): Observable<Book[]>{
    const bookDb = new Array<Book> ();

    this.get_video_db().subscribe(videoDb => {
      for (const video of videoDb) {
        let added = false;

        for (const book of bookDb) {
          if (book.title === video.book && book.author === video.author && ! added) {
            book.videos.push(video);
            added = true;
          }
        }

        if (! added) {
          bookDb.push({
            title: video.book,
            author: video.author,
            book_cover: video.book_cover,
            videos: [
              video
            ]
          });
        }
      }
    });

    return of(bookDb);
  }
}
