<div class="m-3">
  <div>
    <div class="card mt-4 mb-3">
      <!--Image via https://www.pickpik.com/ (free to use for personal and commercial projects)-->
      <img class="card-img-top" src="/assets/img/content-image/immagina-6.jpg">
      <div class="card-body">
        <h3 class="card-title">Immagina</h3>
        <div  class="card-text">
          <p>
            Un gruppo di amici, seduti sul divano, che vogliono sentirsi vicini e si tengono in contatto con lo
            smartphone ai tempi del Coronavirus.
          </p>
          <p>
            Spiriti liberi.
          </p>
          <p>
            Persone che vogliono muoversi, volare, viaggiare, partire, allontanarsi dalla quattro mura - quelle quattro
            mura che proteggono ma allo stesso tempo limitano – ma che ovviamente rispettano le norme di quarantena.
          </p>
          <p>
            Ma c’è una cosa che questa quarantena non ha annullato.
          </p>
          <p>
            Una cosa che nessuno potrà mai bloccare.
          </p>
          <p>
            La grande passione condivisa per la lettura.
          </p>
          <p>
            Tra una chiacchiera in chat e una video-chiamata di gruppo, nasce un’idea.
          </p>
          <p>
            C’è chi va a cercare subito un libro in particolare, un libro che parla della Sardegna e che gli è rimasto
            nel cuore. C’è chi si ferma ad immaginare i posti raccontati nel libro che gli è tornato in mente, i ricordi
            di quando c’è stato realmente in quel bellissimo posto di cui si narra in un romanzo.
          </p>
          <p>
            Immaginali mentre cercano il libro nella propria raccolta casalinga di libri, tra quelli che parlano della
            Sardegna e che evocano la bellezza di questa terra.
          </p>
          <p>
            Immaginali mentre selezionano gli estratti di questi libri, per permettere ad altri di assaporare le
            emozioni di viaggiare attraverso un racconto.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="card-deck">

    <div class="card">
        <!--Image via https://www.pickpik.com/ (free to use for personal and commercial projects)-->
        <img class="card-img-top" src="/assets/img/content-image/cosa-troverete-2.jpg">
        <div class="card-body">
          <h2 class="card-title">Cosa troverete</h2>
          <p class="card-text">
            Un viaggio virtuale nei luoghi e tradizioni della Sardegna attraverso gli occhi dei personaggi che
            vivono nei libri.
            Accompagnato dalle voci dei nostri lettori potrai visitare quei luoghi, meditare e poi ripartire per
            un'altra tappa.

          </p>
        </div>
      </div>

    <div class="card">
        <!--Image via https://www.pickpik.com/ (free to use for personal and commercial projects)-->
        <img class="card-img-top" src="/assets/img/content-image/consigli-per-luso-4.jpg">
        <div class="card-body">
          <h2 class="card-title">Consigli per l'uso</h2>
          <p class="card-text">
            Volando sulla cartina,  potrai gettare la tua ancora su ogni bandierina segnaposto, cliccare ed ascoltare
            un racconto riferito a quel luogo.
            Il sito sarà arricchito ogni settimana di nuovi contenuti e nuove letture.
          </p>
        </div>
      </div>

    <div class="card">
      <img class="card-img-top" src="/assets/img/content-image/maggio-dei-libri-1.png">
      <div class="card-body">
        <h2 class="card-title">Maggio dei Libri</h2>
        <p class="card-text">
          Il Maggio dei Libri è una campagna nazionale che invita a portare
          i libri e la lettura anche in contesti diversi da quelli tradizionali, per intercettare coloro che
          solitamente non leggono ma che possono essere incuriositi se stimolati nel modo giusto.
        </p>
        <p>
          Il tema di quest’anno: Se leggo scopro!
        </p>
      </div>
    </div>

  </div>

</div>

