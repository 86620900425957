<footer class="container-lg card-footer">



  <div class="row d-flex justify-content-center m-1">
    <img src="/assets/img/loghi/logo-arborea-che-legge.jpg" class="logo">
    <img src="/assets/img/loghi/logo-lettura-che-passione.jpg" class="logo">
    <img src="/assets/img/loghi/logo-ass-biblioteca-arborea.jpg" class="logo">
    <img src="/assets/img/loghi/logo-proloco-arborea.jpg" class="logo">
    <img src="/assets/img/loghi/logo-il-maggio-dei-libri.png" class="logo">
  </div>

  <br>

  <div class="row d-md-flex justify-content-between">
    <div class="col-md-3"></div>

    <div class=" col-sm-12 col-md-3 mt-3">
      <div>
        <h5 class="m-0">informazioni</h5>
        <a routerLink="/ringraziamenti">ringraziamenti</a>
      </div>
    </div>

    <div class="col-sm-12 col-md-3 mt-3" >
      <h5 class="m-0">contatti</h5>
      <a href="https://www.facebook.com/arborealegge" target="_blank">Arborea Città Che Legge</a><br>
      <a href="mailto:info@letturachepassione.it">info@letturachepassione.it</a>
    </div>

    <div class="col-md-3"></div>
  </div>


</footer>
