import { Injectable } from '@angular/core';
import { Partner } from './partner';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  private partnerResources = '/assets/data/partners.json';

  constructor(private http: HttpClient) {}

  getPartners(): Observable<Partner[]> {
    return this.http.get<Partner[]>(this.partnerResources);

  }
}
