import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapComponent} from './_pages/map/map.component';
import {AboutUsComponent} from './_pages/about-us/about-us.component';
import {InitiativeComponent} from './_pages/initiative/initiative.component';
import {AcknowledgementsComponent} from './_pages/acknowledgements/acknowledgements.component';
import {BookListComponent} from './_pages/book-list/book-list.component';

const routes: Routes = [
  {path: '', component: MapComponent},
  {path: 'chi-siamo', component: AboutUsComponent},
  {path: 'iniziativa', component: InitiativeComponent},
  {path: 'ringraziamenti', component: AcknowledgementsComponent},
  {path: 'libri', component: BookListComponent}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
