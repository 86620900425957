import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';
import { MarkerService } from '../../_services/map/marker.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  private map;

  constructor(private markerService: MarkerService) { }

  ngOnInit(): void {
    this.initMap();
    this.markerService.makeMarkers(this.map);
  }

  initMap(): void {
    this.map = L.map('map', {
      center: [ 40.088696, 9.104902 ],
      minZoom: 7,
      zoom: 7,
      maxBounds: [
        [45.581147, 4.527246],
        [37.186387, 13.139648]
      ]
    });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }

}
