<div class="m-4 p-2 bg-white rounded d-sm-flex justify-content-between">
  <div>
    <h3 class=""> I LIBRI </h3>
    <p> Ecco tutti i libri delle nostre avventure </p>
    <p> Clicca sul titolo della lettura per ascoltare il racconto. </p>
  </div>
  <img class="rounded displ" src="/assets/img/content-image/immagina-6.jpg" height="140px">

</div>

<div class="card-columns m-4">
  <div *ngFor="let book of bookDB" class="card">
    <h5 class="card-header text-primary"> "{{book.title}}" <br> di {{book.author}} </h5>
    <div class="card-body d-flex pl-3 pr-3 pt-2 pb-2">
      <img class="rounded" src="{{book.book_cover}}" height="150" width="90">
    <div>

    <div class="pl-4 pr-3 pt-2 pb-2" *ngFor="let video of book.videos">
      <a class="text-body" href="https://www.youtube.com/watch?v={{video.youtubeid}}" target="_blank">
        "{{video.title}}" letto da {{video.reader}}
      </a>
    </div>

  </div>
</div>

  </div>

