import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-submit-form',
  templateUrl: './submit-form.component.html',
  styleUrls: ['./submit-form.component.scss']
})
export class SubmitFormComponent implements OnInit {
  submitForm;

  constructor(private formBuilder: FormBuilder) {
    this.submitForm = this.formBuilder.group({
      ftitle: '',
      ftext: '',
      fauthor: '',
      fbook: '',
      fpos: '',
      fnote: ''
    });
  }

  ngOnInit(): void {
  }

  onSubmit(formData) {
    console.warn('Form content', formData);
  }
}
